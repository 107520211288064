<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st7" d="M12.702,24.241c3.83,0,7.659,0.007,11.489-0.01c0.428-0.002,0.566,0.086,0.565,0.553   c-0.016,7.753-0.015,15.507-0.002,23.26c0.001,0.437-0.091,0.579-0.549,0.579c-7.659-0.015-15.319-0.014-22.978-0.002   c-0.413,0.001-0.528-0.112-0.527-0.533c0.012-7.773,0.014-15.547-0.004-23.32c-0.001-0.492,0.173-0.536,0.576-0.534   C5.082,24.247,8.892,24.241,12.702,24.241z"/>
            <path class="st7" d="M39.266,48.611c-3.81,0-7.62-0.01-11.429,0.012c-0.507,0.003-0.608-0.153-0.607-0.637   c0.015-7.713,0.016-15.426-0.002-23.139c-0.001-0.504,0.126-0.619,0.615-0.618c7.6,0.017,15.2,0.018,22.799-0.001   c0.523-0.001,0.647,0.126,0.646,0.656c-0.019,7.693-0.019,15.386,0,23.079c0.001,0.531-0.128,0.665-0.651,0.662   C46.846,48.6,43.056,48.611,39.266,48.611z"/>
            <path class="st7" d="M12.716,51.155c3.809,0,7.618,0.01,11.427-0.012c0.498-0.003,0.613,0.14,0.612,0.633   c-0.016,7.692-0.017,15.383,0.002,23.075c0.001,0.545-0.154,0.653-0.66,0.652c-7.579-0.016-15.157-0.018-22.736,0.003   c-0.564,0.002-0.664-0.177-0.663-0.701c0.016-7.672,0.017-15.343-0.003-23.015c-0.001-0.544,0.146-0.65,0.653-0.647   C5.137,51.165,8.926,51.155,12.716,51.155z"/>
            <path class="st7" d="M39.243,51.156c3.809,0,7.617,0.009,11.426-0.011c0.491-0.003,0.619,0.13,0.618,0.629   c-0.016,7.691-0.017,15.381,0.002,23.072c0.001,0.538-0.143,0.659-0.657,0.658c-7.597-0.017-15.195-0.017-22.792,0   c-0.505,0.001-0.61-0.148-0.609-0.636c0.015-7.711,0.015-15.421,0-23.132c-0.001-0.478,0.127-0.593,0.588-0.591   C31.626,51.164,35.434,51.156,39.243,51.156z"/>
            <path class="st7" d="M82.88,57.186c0.688-1.71,1.371-3.406,2.053-5.103c2.292-5.703,4.586-11.406,6.87-17.112   c0.134-0.335,0.288-0.5,0.68-0.496c2.072,0.022,4.145,0.02,6.217,0.002c0.417-0.004,0.536,0.13,0.536,0.547   c-0.01,9.896-0.01,19.792,0.001,29.688c0.001,0.437-0.146,0.531-0.543,0.525c-1.322-0.022-2.646-0.038-3.967,0.006   c-0.559,0.018-0.729-0.117-0.726-0.713c0.025-5.409,0.014-10.817,0.014-16.226c0-2.023,0.003-4.047-0.004-6.07   c-0.001-0.208,0.083-0.442-0.156-0.676c-0.371,0.933-0.734,1.834-1.088,2.739c-2.654,6.784-5.31,13.566-7.948,20.357   c-0.172,0.444-0.374,0.614-0.852,0.589c-0.846-0.045-1.698-0.036-2.545-0.002c-0.399,0.016-0.591-0.113-0.741-0.492   c-2.958-7.479-5.929-14.953-8.901-22.426c-0.091-0.229-0.12-0.502-0.362-0.67c-0.188,0.217-0.097,0.466-0.098,0.689   c-0.006,7.412-0.01,14.824,0.007,22.236c0.001,0.515-0.101,0.688-0.641,0.668c-1.222-0.047-2.448-0.037-3.671-0.003   c-0.467,0.013-0.592-0.122-0.591-0.597c0.016-5.489,0.01-10.978,0.01-16.467c0-4.347,0.01-8.694-0.013-13.041   c-0.003-0.532,0.133-0.671,0.655-0.663c2.072,0.032,4.145,0.028,6.217,0.002c0.462-0.006,0.688,0.121,0.864,0.575   c2.79,7.202,5.601,14.396,8.41,21.591C82.637,56.82,82.658,57.033,82.88,57.186z"/>
            <path class="st7" d="M219.601,56.32c0,2.784-0.011,5.569,0.011,8.353c0.003,0.455-0.123,0.575-0.563,0.565   c-1.341-0.029-2.685-0.033-4.026,0.001c-0.49,0.013-0.625-0.122-0.623-0.627c0.019-5.488,0.003-10.977,0.025-16.465   c0.002-0.567-0.112-0.768-0.702-0.727c-0.825,0.057-1.657,0.003-2.486,0.02c-0.304,0.006-0.417-0.087-0.413-0.406   c0.015-1.142,0.016-2.284-0.001-3.425c-0.005-0.331,0.131-0.402,0.421-0.398c0.848,0.013,1.699-0.023,2.545,0.015   c0.48,0.022,0.639-0.102,0.635-0.618c-0.01-1.239-0.072-2.48,0.078-3.72c0.439-3.623,3.241-6.347,6.833-6.586   c1.143-0.076,2.285-0.07,3.412,0.215c0.355,0.09,0.53,0.219,0.52,0.634c-0.029,1.161-0.027,2.324-0.001,3.485   c0.01,0.438-0.124,0.461-0.468,0.312c-0.773-0.335-1.587-0.507-2.424-0.411c-1.653,0.19-2.649,1.345-2.75,3.218   c-0.055,1.019-0.01,2.043-0.031,3.064c-0.007,0.327,0.123,0.403,0.419,0.401c2.25-0.009,4.5-0.014,6.75,0.004   c0.412,0.003,0.491-0.161,0.487-0.531c-0.016-1.322,0.004-2.644-0.013-3.966c-0.004-0.352,0.093-0.542,0.446-0.646   c1.4-0.411,2.796-0.837,4.184-1.286c0.438-0.142,0.54-0.028,0.535,0.416c-0.019,1.823,0.003,3.646-0.016,5.468   c-0.004,0.409,0.095,0.554,0.521,0.546c1.381-0.026,2.763,0.006,4.144-0.019c0.418-0.007,0.54,0.126,0.53,0.543   c-0.026,1.061-0.022,2.123-0.001,3.184c0.008,0.385-0.097,0.518-0.497,0.511c-1.401-0.025-2.802,0.004-4.203-0.019   c-0.393-0.006-0.494,0.119-0.493,0.507c0.015,3.425,0.009,6.85,0.016,10.276c0.001,0.298,0.036,0.6,0.087,0.894   c0.319,1.819,1.46,2.619,3.272,2.303c0.508-0.089,1.006-0.219,1.447-0.497c0.303-0.19,0.367-0.079,0.365,0.226   c-0.006,1.142-0.01,2.284,0.003,3.425c0.004,0.312-0.115,0.482-0.398,0.576c-2.279,0.752-4.571,0.952-6.815-0.058   c-1.784-0.802-2.579-2.383-2.932-4.244c-0.165-0.87-0.18-1.753-0.183-2.632c-0.013-3.365-0.019-6.73,0.008-10.095   c0.005-0.568-0.167-0.69-0.694-0.683c-2.131,0.029-4.263,0.028-6.394,0c-0.48-0.006-0.583,0.144-0.58,0.604   C219.611,50.792,219.601,53.556,219.601,56.32z"/>
            <path class="st7" d="M157.72,65.734c-1.771,0.02-3.361-0.211-4.88-0.839c-2.866-1.186-4.77-3.304-5.671-6.284   c-0.866-2.864-0.841-5.774,0.064-8.627c1.3-4.096,4.167-6.379,8.29-7.099c2.023-0.353,4.048-0.314,6.04,0.222   c3.226,0.868,5.479,2.889,6.566,6.087c1.165,3.429,1.145,6.903-0.275,10.259c-1.519,3.588-4.331,5.534-8.091,6.141   C159.039,65.712,158.312,65.741,157.72,65.734z M163.594,54.234c0.014-0.662-0.016-1.322-0.129-1.975   c-0.058-0.334-0.124-0.668-0.207-0.997c-0.653-2.585-2.498-4.172-4.976-4.288c-2.896-0.136-4.946,1.16-5.849,3.783   c-0.764,2.218-0.775,4.483-0.095,6.723c0.563,1.855,1.737,3.163,3.618,3.72c0.919,0.272,1.86,0.308,2.811,0.199   c1.723-0.198,3.056-0.996,3.885-2.574C163.408,57.39,163.59,55.83,163.594,54.234z"/>
            <path class="st7" d="M210.318,53.799c-0.019,2.268-0.324,4.252-1.232,6.099c-1.269,2.581-3.245,4.357-5.959,5.225   c-2.705,0.865-5.442,0.873-8.15,0.031c-3.825-1.189-6.489-4.648-6.877-8.767c-0.195-2.073-0.142-4.121,0.457-6.121   c1.267-4.237,4.132-6.637,8.384-7.381c2.104-0.368,4.212-0.329,6.268,0.285c3.788,1.131,5.957,3.786,6.803,7.636   C210.243,51.866,210.282,52.946,210.318,53.799z M205.034,54.257c-0.022-1.039-0.111-2.033-0.351-3.009   c-0.606-2.454-2.275-3.948-4.762-4.26c-2.436-0.306-4.613,0.784-5.71,2.898c-0.906,1.747-1.029,3.646-0.864,5.558   c0.297,3.443,2.09,5.865,5.613,6.006c2.532,0.101,4.625-0.837,5.56-3.683C204.898,56.617,204.99,55.422,205.034,54.257z"/>
            <path class="st7" d="M184.622,48.423c-1.227-0.839-2.49-1.32-3.846-1.521c-0.86-0.128-1.736-0.189-2.584,0.073   c-0.887,0.274-1.57,0.808-1.67,1.813c-0.096,0.97,0.244,1.757,1.11,2.28c1.022,0.618,2.135,1.03,3.213,1.521   c1.358,0.618,2.681,1.306,3.645,2.499c2.221,2.747,1.509,7.008-1.483,9.076c-1.36,0.94-2.887,1.37-4.496,1.521   c-2.234,0.209-4.401-0.139-6.517-0.881c-0.498-0.174-0.714-0.403-0.689-0.979c0.053-1.24,0.013-2.484,0.021-3.726   c0.001-0.168-0.096-0.408,0.104-0.482c0.098-0.036,0.275,0.114,0.404,0.199c1.799,1.178,3.745,1.859,5.908,1.809   c0.516-0.012,1.024-0.099,1.509-0.274c1.481-0.535,1.863-2.416,0.714-3.508c-0.555-0.528-1.239-0.848-1.918-1.172   c-1.212-0.577-2.44-1.116-3.637-1.733c-4.021-2.075-4.265-8.547,0.15-11.006c2.149-1.197,4.442-1.439,6.825-1.12   c0.958,0.128,1.905,0.321,2.817,0.662c0.204,0.076,0.423,0.126,0.423,0.42C184.621,45.367,184.622,46.841,184.622,48.423z"/>
            <path class="st7" d="M129.78,48.838c-1.336-0.948-2.63-1.576-4.114-1.802c-3.64-0.555-6.846,1.765-7.439,5.468   c-0.316,1.971-0.256,3.92,0.683,5.74c0.985,1.909,2.593,2.892,4.671,3.151c2.017,0.252,3.814-0.367,5.504-1.416   c0.198-0.123,0.394-0.251,0.589-0.375c0.171,0.175,0.101,0.365,0.102,0.534c0.007,1.042-0.066,2.09,0.026,3.123   c0.075,0.843-0.269,1.226-0.987,1.485c-4.319,1.563-8.49,1.575-12.287-1.373c-2.083-1.617-3.188-3.9-3.595-6.496   c-0.422-2.685-0.149-5.322,0.964-7.815c1.595-3.576,4.428-5.522,8.174-6.182c2.316-0.408,4.592-0.212,6.826,0.547   c0.632,0.215,0.953,0.506,0.906,1.271C129.721,46.028,129.78,47.367,129.78,48.838z"/>
            <path class="st7" d="M138.812,46.869c0.175-0.348,0.34-0.702,0.526-1.044c1.308-2.394,3.788-3.491,6.38-2.837   c0.363,0.092,0.569,0.218,0.558,0.67c-0.033,1.381-0.019,2.763-0.007,4.145c0.003,0.381-0.005,0.577-0.461,0.313   c-0.586-0.338-1.25-0.489-1.921-0.552c-2.08-0.197-3.583,0.748-4.479,2.793c-0.489,1.117-0.674,2.3-0.678,3.507   c-0.014,3.584-0.016,7.168,0.005,10.753c0.003,0.504-0.137,0.636-0.624,0.623c-1.341-0.034-2.683-0.023-4.024-0.004   c-0.355,0.005-0.474-0.088-0.473-0.467c0.011-7.028,0.01-14.057,0.001-21.085c-0.001-0.368,0.104-0.479,0.466-0.474   c1.401,0.019,2.802,0.021,4.202-0.001c0.388-0.006,0.453,0.152,0.448,0.494c-0.017,1.048-0.006,2.097-0.006,3.146   C138.752,46.855,138.782,46.862,138.812,46.869z"/>
            <path class="st7" d="M104.129,54.171c0-3.464,0.008-6.929-0.01-10.393c-0.002-0.445,0.106-0.583,0.556-0.573   c1.341,0.031,2.683,0.025,4.025,0.003c0.397-0.007,0.543,0.085,0.542,0.523c-0.013,6.989-0.013,13.977-0.001,20.966   c0.001,0.415-0.106,0.548-0.525,0.54c-1.361-0.026-2.723-0.028-4.084,0.001c-0.438,0.009-0.513-0.156-0.511-0.554   C104.135,61.18,104.129,57.675,104.129,54.171z"/>
            <path class="st7" d="M109.835,36.846c0.004,1.688-1.364,2.998-3.113,2.982c-1.718-0.016-3.071-1.328-3.069-2.977   c0.002-1.694,1.352-3.005,3.096-3.005C108.52,33.846,109.831,35.12,109.835,36.846z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'microsoft-brand',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>
